import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Elias Yetwale </span>
            from <span className="purple"> Addis Abeba, Ethiopia.</span>
            <br />
             and I am a software developer. I am passionate about creating high-quality software solutions that solve real-world problems and improve the user experience.

I have more than 2 years of experience in software development and have worked with various technologies and programming languages. My expertise lies in web development, mobile app development, or backend development. I am well-versed in JavaScript, React, Node.js, PHP.

Throughout my career, I have successfully delivered projects by collaborating with cross-functional teams, including designers, product managers, and QA engineers. I have a strong understanding of software development methodologies, such as Agile, and I am comfortable working in both team-based and independent environments.

I am constantly learning and staying up-to-date with the latest industry trends and advancements. I am a problem-solver at heart, and I enjoy tackling complex challenges and finding innovative solutions.

In addition to my technical skills, I am a strong communicator and believe in effective collaboration and teamwork. I am always eager to expand my knowledge and contribute to the growth and success of the teams I work with.

Thank you for considering my introduction. I am excited about the opportunity to apply my skills and contribute as a software developer. I look forward to discussing how I can add value to your projects and contribute to your organization's success."

Feel free to customize and expand upon this introduction based on your specific experiences and qualifications as a software developer.          </p>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Strive to build things that make a difference!"{" "}
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
