import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/image_fixed_width .png";
import chatify1 from "../../Assets/image_large.png";
import botimg from "../../Assets/bot.png"
import booking from '../../Assets/OIP.jpeg'
import chatify5 from '../../Assets/OIP (1).jpeg'
import axios from "axios";
function Projects() {

  const URL = "https://protofolio.dev-elias.com/list"
  const [posts,setPosts] = useState([])


  useEffect(()=>{

    (async ()=>{
      axios.get(URL)
      .then((result) => {
        setPosts(result.data)
      }).catch((err) => {
        console.log(err)
        alert("Error with the internet connection")
      });
    })()

    

  },[])


  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

   {  posts.map((post)=> 
   <Col md={4} className="project-card">
   <ProjectCard
     imgPath={post.img}
     isBlog={false}
     title={post.title}
     description={post.description}
     demoLink={post.demolink}
   />
 </Col>
   )
          }
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
